import 'trix'
import '@rails/actiontext'
import './js/bootstrap_js_files.js'
import Tooltip from 'bootstrap/js/src/tooltip'

// DOM ready
document.addEventListener('DOMContentLoaded', () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
});
